<template>
  <div
    ref="fadeInContainer"
    class="fade-in-background"
    :style="{
      background: background
    }"
  >
    <div
      class="fade-in-container"
      :class="{
        animation: showContent && !disableAnimation,
        'hide-block': !showContent && !disableAnimation,
        'no-animation': disableAnimation
      }"
      :style="{
        '--animation-duration': `${duration}s`,
        '--animation-transform': `${transform}px`,
        '--animation-show-delay': `${delay}`
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  aniShowTop: {
    type: Number,
    default: 50
  },
  aniHideTop: {
    type: Number,
    default: 50
  },
  duration: {
    type: Number,
    default: 1
  },
  transform: {
    type: Number,
    default: 200
  },
  background: {
    type: String,
    default: "transparent"
  },
  delay: {
    type: Number,
    default: 0.2
  },
  oneByOne: {
    type: Boolean,
    default: false
  },
  disableAnimation: {
    type: Boolean,
    default: false
  }
})

const showContent = ref<boolean>(false)

const fadeInContainer = ref()

onMounted(() => {
  handleScroll()
  nextTick(() => {
    window.addEventListener("scroll", handleScroll, true)
  })
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll, true)
})

const emits = defineEmits(["onShow", "onHide"])

const onHideContent = useThrottle(() => {
  emits("onHide")
}, 1)

const onShowContent = useThrottle(() => {
  emits("onShow")
}, 1)

const handleScroll = () => {
  nextTick(() => {
    const domRef: any = fadeInContainer.value
    if (!domRef) {
      return
    }
    const position = domRef.getBoundingClientRect().top
    const clientHeight = document.documentElement.clientHeight
    if (showContent.value) {
      if (showContent.value !== position + props.aniHideTop <= clientHeight) {
        onHideContent()
      }
      showContent.value = position + props.aniHideTop <= clientHeight
    } else {
      if (showContent.value !== position + props.aniShowTop <= clientHeight) {
        onShowContent()
      }
      showContent.value = position + props.aniShowTop <= clientHeight
    }
  })
}
</script>

<style lang="scss" scoped>
.fade-in-background {
  .fade-in-container {
    opacity: 0;

    &.no-animation {
      opacity: 1;
      transform: none;

      @for $i from 1 through 20 {
        :deep(.delay-#{$i}) {
          opacity: 1;
          transform: none;
          animation: none;
        }
      }
    }
  }

  $maxCount: 20;
  $animation-show-delay: var(--animation-show-delay);

  :deep(.animation) {
    @for $i from 1 through $maxCount {
      .delay-#{$i} {
        transition: all var(--animation-duration);
        animation: fadeIn backwards var(--animation-duration) ease;
        animation-delay: calc(($i - 1) * #{$animation-show-delay} * 1s);
      }
    }
  }

  .animation {
    opacity: 1;
  }

  .hide-block {
    opacity: 0;
  }
}
</style>
